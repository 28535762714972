import {
  AnswerType,
  DocumentType,
  QuestionType,
  UserType,
} from '@innedit/innedit-type';
import { GenericData, QuestionData, UserData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemAnswer: FC<
  ListItemProps<AnswerType, GenericData<AnswerType>>
> = ({ docId, index, onClick, model }) => {
  const { t } = useTranslation();

  const [doc, setDoc] = useState<DocumentType<AnswerType>>();
  const [user, setUser] = useState<DocumentType<UserType>>();
  const [question, setQuestion] = useState<DocumentType<QuestionType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
        if (document?.questionId) {
          const questionData = new QuestionData({
            espaceId: document.espaceId,
          });
          questionData
            .findById(document.questionId)
            .then(snapshot => {
              if (isMounted) {
                setQuestion(snapshot);
              }

              return isMounted;
            })
            .catch(error => toast.error(error.message));
        }
        if (document?.userId) {
          const userData = new UserData();
          userData
            .findById(document.userId)
            .then(snapshot => {
              if (isMounted) {
                setUser(snapshot);
              }

              return isMounted;
            })
            .catch(error => toast.error(error.message));
        }
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      date={doc.createdAt}
      description={
        question && 0 === question.nbChildren
          ? doc.label
          : t('answers.item.description.nb', {
              count: question?.nbChildren ?? 0,
            })
      }
      displayActionId={false}
      doc={doc}
      index={index}
      label={question?.label ?? doc.questionId}
      onClick={onClick}
      subLabel={`${user?.firstName} ${user?.lastName}`}
    />
  );
};

export default ListItemAnswer;
